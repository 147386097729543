// import { handleErrors } from '../utils/errorUtils';
// import { sendLogoutRequest } from '../api/loginApi';

import { CLEAR_LOGIN_DATA } from '../constants';
import { navigateToLogin } from '../utils/navigationUtils';
import { clearLocalStorage } from '../utils/localStorageUtils';
import { WIDGET_ORDER_STORAGE_KEY, WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS } from '../constants/enums/localStorageKeys';

export const clearLoginData = () => ({
  type: CLEAR_LOGIN_DATA,
  payload: {},
});

export const logout = () => async dispatch => {
  // const resp = await sendLogoutRequest(fetch);
  // handleErrors(resp);
  // await resp.json();
  const dispatchPromise = await dispatch(clearLoginData());
  await dispatchPromise;
  // window.localStorage.clear();
  clearLocalStorage([
    WIDGET_ORDER_STORAGE_KEY,
    WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS.urgentIssues,
    WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS.security,
    WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS.recommendedMaintenance,
    WIDGET_ITEMS_VISIBILITY_STORAGE_KEYS.trailerUtilization,
  ]);
  navigateToLogin();
};
