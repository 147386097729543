export const fetchUserManagementApi = `{
    users {
      id
      email
      accountStatus
      enabled
      emailVerified
      createdDate
      lastModifiedDate
      lastLoginDateTime
      groups {
        id
        name
      }
      customers {
        id
        shortName
        longName
        abbrev
        vehicleGroups {
          id
          name
        }
      }
    }
  }`;
